export const HeaderLinks = [
  {
    id: 1,
    title: "Home",
    url: "/",
  },
  {
    id: 2,
    title: "Our Work",
    url: "/work",
  },
  {
    id: 3,
    title: "About Us",
    url: "/about",
  },
  {
    id: 4,
    title: "Contact Us",
    url: "/contact",
  },
];

export const foundingMembers = [
  
  {
    image: "https://ik.imagekit.io/princeSherwa/Sherwa/prince.jpeg",
    color: " from-purple-600 via-pink-600 to-blue-900 ",
    name: "Prince Chaurasia",
    about:
      "Creative Engineer specializing in stunning UI/UX designs tailored to enhance business and user experience.",
  },
  {
    image:
      "https://ik.imagekit.io/princeSherwa/Sherwa/dev_anshu.png?updatedAt=1715888600565",
    color: " from-purple-600 via-pink-600 to-blue-900 ",
    name: "Anshu Pandey",
    about:
      "Skilled Engineer with a focus on complex business logic and database architecture, excelling in sophisticated software implementation.",
  },
];

export const cardDetails = [
  {
    id: 1,
    image: "https://ik.imagekit.io/princeSherwa/Sherwa/guru.png",
    link: "https://saydata.tech/",
    isPublic: true,
    heading: "A.I. Spiritual master",
    description:
      "Connect with spiritual masters effortlessly through our AI chatbot. Choose your guide and engage in meaningful conversations, guiding you on your spiritual journey.",
    info: "Connect with spiritual masters effortlessly through our AI chatbot. Choose your guide and engage in meaningful conversations, guiding you on your spiritual journey.",
  },
  {
    id: 2,
    image: "https://ik.imagekit.io/princeSherwa/Sherwa/toys.jpg",
    link: "https://www.barts.eu/",
    isPublic: true,
    heading: "E-commerce website for toys.",
    description:
      "Experience the magic of our toy eCommerce website, where imagination knows no bounds. From classic favorites to innovative wonders, find the perfect toy for every child, sparking joy and igniting their imagination with endless possibilities.",

    info: "Connect with spiritual masters effortlessly through our AI chatbot. Choose your guide and engage in meaningful conversations, guiding you on your spiritual journey.",
  },
  {
    id: 3,
    image: "https://ik.imagekit.io/princeSherwa/Sherwa/lms.jpg",
    link: "https://www.ayraa.io/",
    isPublic: false,
    heading: "Ed-tech LMS for a leadership program",
    description:
      "Embark on a transformative journey with our Ed-Tech LMS tailored for leadership programs. Empower leaders with interactive courses, insightful resources, and personalized learning experiences, shaping tomorrow's trailblazers with knowledge and wisdom.",

    info: "Connect with spiritual masters effortlessly through our AI chatbot. Choose your guide and engage in meaningful conversations, guiding you on your spiritual journey.",
  },
  {
    id: 4,
    image: "https://ik.imagekit.io/princeSherwa/Sherwa/sanathana.png",
    link: "https://www.sanathanasarathi.in/",
    isPublic: true,
    heading: "Sanathana Sarthi Subscriber Management Web-Application",
    description:
      "Elevate your subscriber management with our intuitive web app. Streamline operations, engage subscribers, and track metrics effortlessly. Unlock the power of seamless subscriber management, optimizing your outreach and fostering lasting connections",

    info: "Connect with spiritual masters effortlessly through our AI chatbot. Choose your guide and engage in meaningful conversations, guiding you on your spiritual journey.",
  },
  {
    id: 5,
    image: "https://ik.imagekit.io/princeSherwa/Sherwa/ai-interior.jpg",
    link: "https://www.nexa4ai.com/",
    isPublic: false,
    heading: "Interior Design using A.I.",
    description:
      "Experience next-level design with our AI-powered interior design website. Tailored recommendations, immersive visualizations, and personalized solutions redefine your space, creating environments that reflect your unique style and exceed your expectations.",

    info: "Connect with spiritual masters effortlessly through our AI chatbot. Choose your guide and engage in meaningful conversations, guiding you on your spiritual journey.",
  },
  {
    id: 6,
    image: "https://ik.imagekit.io/princeSherwa/Sherwa/industry.jpg",
    link: "https://sssnlp.com/",
    isPublic: true,
    heading: "learn from industry expert",
    description:
      "Unlock wisdom with our platform, learning directly from experts. Dive deep into specialized knowledge, gaining insights and skills directly from industry leaders, empowering you to excel in your field.",

    info: "Connect with spiritual masters effortlessly through our AI chatbot. Choose your guide and engage in meaningful conversations, guiding you on your spiritual journey.",
  },
];

export const workCard = [
  {
    image:
      "https://ik.imagekit.io/princeSherwa/Sherwa/ai.jpg?updatedAt=1714160512124",
    title: "AI",
    link: "/ai",
    discription: "Revolutionizing Intelligence, Empowering Innovation",
    type: "fade-up",
  },
  {
    image:
      "https://ik.imagekit.io/princeSherwa/Sherwa/edtech.jpg?updatedAt=1714160512969",
    title: "Ed Tech",
    link: "/edtech",
    discription: "Education Re-imagined for Digital Futures",
    type: "fade-up",
  },
  {
    image:
      "https://ik.imagekit.io/princeSherwa/Sherwa/ecommerce.png?updatedAt=1714160512215",
    title: "E-Commerce",
    link: "/ecommerce",
    discription: "Streamlining Commerce with Digital Expertise",
    type: "fade-up",
  },
];

export const aiprojects = [
  {
    id: 1,
    img: "https://ik.imagekit.io/princeSherwa/Sherwa/Screenshot%202024-04-27%20023643.png",
    link: "https://www.nexa4ai.com/",
    title: "Nexa4ai.com",
    description: "On-Device AI Agents",
  },
  {
    id: 2,
    img: "https://ik.imagekit.io/princeSherwa/Sherwa/Screenshot%202024-04-27%20023714.png",
    link: "https://www.ayraa.io/",
    title: "Ayraa.io",
    description: "Personal Knowledge Assistant ",
  },
];

export const edtechprojects = [
  {
    id: 1,
    img: "https://ik.imagekit.io/princeSherwa/Sherwa/Screenshot%202024-04-27%20023733.png",
    link: "https://sssnlp.com/",
    title: "SSSNLP",
    description: "Leadership Programme for Self Transformation",
  },
  {
    id: 2,
    img: "https://ik.imagekit.io/princeSherwa/Sherwa/Screenshot%202024-04-27%20023755.png",
    link: "https://www.calypsoedu.com/",
    title: "CalypsoEdu",
    description: "Calypso Continuing Education",
  },
  {
    id: 3,
    img: "https://ik.imagekit.io/princeSherwa/Sherwa/Screenshot%202024-04-27%20023812.png",
    link: "https://www.certifiedskills.academy/",
    title: "CertifiedSkills",
    description: "Get Certified with certifiedskills.academy",
  },
  {
    id: 4,
    img: "https://ik.imagekit.io/princeSherwa/Sherwa/Screenshot%202024-04-27%20023953.png",
    link: "http://croatopica.net/",
    title: "Croatopia",
    description: "Croatian heritage university level courses",
  },
  {
    id: 5,
    img: "https://ik.imagekit.io/princeSherwa/Sherwa/Screenshot%202024-04-27%20023849.png",
    link: "https://rushce.rush.edu/",
    title: "Rushce",
    description: "Education and Courses",
  },
];

export const ecommerceprojects = [
  {
    id: 1,
    img: "https://ik.imagekit.io/princeSherwa/Sherwa/Screenshot%202024-04-27%20023451.png",
    link: "https://puredailycare.com/",
    title: "puredailycare.com",
    description: "#1 Best Seller. NuDerma.",
  },
  {
    id: 2,
    img: "https://ik.imagekit.io/princeSherwa/Sherwa/Screenshot%202024-04-27%20023521.png",
    link: "https://www.barts.eu/",
    title: "barts.eu",
    description: "Luxury Clothing",
  },
  {
    id: 3,
    img: "https://ik.imagekit.io/princeSherwa/Sherwa/Screenshot%202024-04-27%20023537.png",
    link: "https://www.indexd.com/",
    title: "Indexd",
    description: "Design has an impact on everything.",
  },
  {
    id: 4,
    img: "https://ik.imagekit.io/princeSherwa/Sherwa/Screenshot%202024-04-27%20023602.png",
    link: "https://www.sanathanasarathi.in/",
    title: "Sanathana Sarathi",
    description: "Official Website for Sanathana Sarthi",
  },
];
