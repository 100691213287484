import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import AOS from "aos";
import "aos/dist/aos.css";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import OurWork from "./pages/OurWork";
import Header from "./common/Header";
import Footer from "./common/Footer";
import DownToTopBtn from "./fragments/DownToTopBtn";
import AiWebs from "./pages/AiWebs";
import EdTechWebs from "./pages/EdTechWebs";
import EcommerceWebs from "./pages/EcommerceWebs";
import { FloatingButton } from "./ui/Buttons";

AOS.init();

function App() {
  return (
    <Router>
      <Header />
      <DownToTopBtn />
      <FloatingButton/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/work" element={<OurWork />} />
        <Route path="/about" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/ai" element={<AiWebs />} />
        <Route path="/edtech" element={<EdTechWebs />} />
        <Route path="/ecommerce" element={<EcommerceWebs />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
