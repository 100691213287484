import React from "react";
import { aiprojects } from "../fragments/Data";
import { Link } from "react-router-dom";

const AiWebs = () => {
  return (
    <div className="pt-10 px-8 md:px-20 inter-regular">
      <div className="flex flex-col gap-16 p-20">
        <p className="text-3xl text-center p-3 rounded-xl font-bold text-white bg-gradient-to-tr from-blue-900 via-sky-900 to-blue-500  ">
          Our AI projects
        </p>
        {aiprojects.map((item) => (
          <Link
            target="blank"
            to={item.link}
            key={item.id}
            className="text-white p-2 rounded-xl flex flex-col gap-5 shadow-lg shadow-[#255370]"
          >
            <img src={item.img} alt={item.title} className="rounded-xl" />
            <div className="text-center">
              <p className="text-3xl font-bold">{item.title}</p>
              <p>{item.description}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AiWebs;
