import React from "react";
import { Link } from "react-router-dom";

const WorkWithUs = () => {
  return (
    <div
      data-aos="fade-up"
      className="md:min-h-screen w-full md:px-24 pb-10 flex flex-col md:flex-row "
    >
      <div className=" w-full md:w-[50%] flex justify-center items-center">
        <img
          className=" shape shadow-xl shadow-[#1C3D62] md:h-[70%] h-[45%] object-contain myanimation2"
          src="https://ik.imagekit.io/princeSherwa/Sherwa/tech-meet.jpg?updatedAt=1716317911259"
          alt="calendar"
        />
      </div>
      <div className="md:w-[50%] flex flex-col justify-center text-center md:text-left gap-12 px-3 md:px-8">
        <p className="text-5xl font-extrabold text-white text-center md:text-left">
          Work with Us!
        </p>
        <p className="text-slate-400 font-semibold px-8 md:px-0">
          Ready to transform your technical challenges into innovative
          solutions? Let's schedule a meeting to collaborate and conquer those
          creative hurdles together. Reach out today to book a session and
          embark on a journey to unlock your full potential!
        </p>
        <Link
          target="blank"
          className="bg-[#2F669A] text-[#fff] bg-gradient-to-tr from-[#2F669A] via-[#0c1c36] to-[#2F669A] text-center py-3 rounded-2xl font-bold text-xl hover:scale-95 duration-200"
          to="https://zcal.co/amansahu/60min"
        >
          Schedule a meeting
        </Link>
      </div>
    </div>
  );
};

export default WorkWithUs;
