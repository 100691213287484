import React from "react";
import { MemberCard, SmallScreenMemberCard } from "../fragments/Cards";
import { foundingMembers } from "../fragments/Data";
import logo from "../assets/logo.png";
import useScrollToTopOnMount from "../ui/useScrollToTopOnMount";

const Team = () => {
  useScrollToTopOnMount();
  return (
    <div className="h-fit md:min-h-screen bg-[#0A0C11] relative w-full flex flex-col items-center pt-20 inter-regular">
      <div className="w-full md:px-20 px-4 md:mt-12 mb-12 gap-12 flex flex-col md:flex-row justify-between pt-8">
        <div className="md:w-[50%] relative flex justify-center items-center h-fit">
          <img
            className="rounded-xl blur-sm shadow-lg shadow-[#67b9da] "
            src="https://ik.imagekit.io/princeSherwa/Sherwa/sherwa.jpg"
            alt="sherwa"
          />
          <img src={logo} alt="logo" className="absolute animate-pulse h-24 md:h-fit" />
        </div>
        <div className="md:w-[50%] grid place-items-center gap-8">
          <p className="text-center text-5xl inter-black bg-gradient-to-r from-[#085F77] via-[#b8e4f1] to-[#052b33] inline-block text-transparent bg-clip-text">
            Sherwa.tech
          </p>
          <div className=" text-center md:text-left md:pl-10 text-white flex flex-col gap-4 tracking-wide font-semibold">
            <p>
              Sherwa.Tech takes inspiration from the resilient Sherpa community
              of the Himalayas, offering essential tech support and consultancy
              services to startups and non-tech organizations. Like Sherpas
              guiding climbers, we navigate the digital terrain alongside our
              clients, providing expert assistance and guidance tailored to
              their needs.
            </p>
            <p className="hidden md:block">
              Our mission is clear: to serve as trusted partners, empowering
              businesses to thrive in the digital age. Through our comprehensive
              tech support and consultancy services, we ensure that all clients,
              regardless of their technical background, can harness the power of
              technology for growth and success.
            </p>
            <p className="hidden md:block">
              With a focus on inclusivity and accessibility, Sherwa.Tech equips
              businesses with the tools and knowledge they need to conquer
              challenges and achieve their goals. Join us as we pave the way for
              innovation and transformation in today's rapidly evolving digital
              landscape.
            </p>
          </div>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="z-40 h-fit flex flex-col justify-center items-center md:pt-0 xl:mt-12"
      >
        <p className="text-4xl md:text-7xl font-extrabold text-center mt-3 tracking-widest uppercase text-[#2F669A]">
          meet our Team
        </p>
      </div>
      <div
        data-aos="fade-up"
        className="flex flex-col flex-wrap w-full h-fit justify-center items-center xl:px-32 mt-10 md:mt-0 md:py-12 mb-6"
      >
        <p className="text-2xl xl:text-4xl text-white font-extrabold">
          Founding Members
        </p>
        <div className="w-full hidden xl:flex flex-wrap items-center justify-around gap-10 pt-10 px-6">
          {foundingMembers.map((member, index) => (
            <MemberCard
              key={index}
              image={member.image}
              name={member.name}
              about={member.about}
            />
          ))}
        </div>
        <div className="w-full flex xl:hidden flex-wrap items-center justify-around gap-10 pt-10 px-6">
          {foundingMembers.map((member, index) => (
            <SmallScreenMemberCard
              key={index}
              image={member.image}
              name={member.name}
              about={member.about}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
