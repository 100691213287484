import React from "react";
import Hero from "../components/Hero";
import VideoComponent from "../components/VideoComponent";
import Work from "../components/Work";
import WorkWithUs from "../components/WorkWithUs";
import useScrollToTopOnMount from "../ui/useScrollToTopOnMount";

const Home = () => {
  useScrollToTopOnMount();
  return (
    <div className="h-fit w-full inter-regular">
      <Hero />
      <Work />
      <VideoComponent />
      <WorkWithUs />
    </div>
  );
};

export default Home;
