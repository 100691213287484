import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/effect-fade";
import { ProjectDisplayCard } from "../fragments/Cards";

const Carousel = ({ project }) => {
  return (
    <Swiper
      modules={[EffectFade, Autoplay, Pagination]}
      effect="fade"
      pagination={{ clickable: true }}
      autoplay
    >
      {project.map((item) => (
        <SwiperSlide>
          <ProjectDisplayCard {...item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;
