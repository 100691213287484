import React from "react";
import logo from "../assets/logo.png";
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className=" h-fit flex flex-col items-center xl:flex-row gap-8 pb-16 lg:py-12 xl:px-32 justify-between border-t-2 text-white w-full bg-[#0A0C11]">
      <div className=" flex justify-center items-center gap-4 text-left mt-4">
        <img className="h-12 w-12 object-cover" src={logo} alt="" />
        <span>
          <p className="text-xl lg:text-3xl  font-bold">Sherwa Tech</p>
          <p className="text-xs mt-2">Guiding Your Tech Accent</p>
        </span>
      </div>
      <div className="flex justify-between items-center gap-8 lg:gap-80">
        <div className="text-left xl:text-center">
          <p className="text-md xl:text-xl font-bold">Info@Sherwa.Tech</p>
          <p className="mt-4">+91 9993374787</p>
        </div>
        <div className=" flex flex-col justify-center items-start gap-4">
          <p className="text-md xl:text-xl font-bold">Connect with us at:</p>
          <div className="grid grid-cols-4 lg:gap-4 gap-6 ">
            <Link
              target="_blank"
              to={"https://www.linkedin.com/company/sherwatech"}
              className="hover:scale-95 pointer-events-none duration-200 rounded-xl h-fit md:p-2 flex justify-center items-center md:shadow-md shadow-sky-600"
            >
              <FaLinkedinIn size={24} />
            </Link>
            <Link
              to={"https://wa.link/ep19st"}
              target="_blank"
              className="hover:scale-95 duration-200 rounded-xl h-fit md:p-2 flex justify-center items-center md:shadow-md shadow-sky-600"
            >
              <FaWhatsapp size={24} />
            </Link>
            <Link
              target="_blank"
              to={"https://twitter.com/SherwaTech"}
              className="hover:scale-95 duration-200 rounded-xl h-fit md:p-2 flex justify-center items-center md:shadow-md shadow-sky-600"
            >
              <FaTwitter size={24} />
            </Link>
            <Link
              target="_blank"
              to={"https://www.instagram.com/sherwa.tech/"}
              className="hover:scale-95 duration-200 rounded-xl h-fit md:p-2 flex justify-center items-center md:shadow-md shadow-sky-600"
            >
              <FaInstagram size={24} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
