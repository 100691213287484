import React from "react";
import useScrollToTopOnMount from "../ui/useScrollToTopOnMount";
import Dropdown from "../ui/DropDown";
import Carousel from "../components/Carousel";
import {
  aiprojects,
  ecommerceprojects,
  edtechprojects,
} from "../fragments/Data";

const OurWork = () => {
  useScrollToTopOnMount();
  return (
    <div className="h-fit bg-[#0A0C11] xl:pt-24 pt-12 inter-regular ">
      <div className=" h-fit pb-8 flex justify-center">
        <div className="h-[300px] md:h-[550px] w-[95%] overflow-hidden mt-8 rounded-xl flex justify-center items-center shadow-md shadow-sky-400/[0.3] relative">
          <img
            className="h-full absolute w-full hover:scale-110 duration-200 object-cover"
            src="https://ik.imagekit.io/princeSherwa/Sherwa/tech.jpg?updatedAt=1713806661317"
            alt=""
          />
          <p className="absolute flex text-5xl font-extrabold text-white tracking-wider">
            Our Work
          </p>
        </div>
      </div>
      <div className="text-[#DA0D97] md:px-20 my-8 space-y-5">
        <p className="text-center text-2xl md:text-4xl font-bold tracking-wider underline underline-offset-4">
          Explore Our Work
        </p>
        <p className="text-center md:text-3xl inter-regular text-white px-2">
          Our team is composed of seasoned professionals with profound expertise
          in Artificial Intelligence, Education Technology and E-Commerce
          demonstrate our commitment to staying at the forefront of industry
          advancements.
        </p>
      </div>
      <div className="md:flex flex-col hidden">
        <div className="p-20 space-y-8">
          <p className="text-3xl text-center p-3 rounded-xl font-bold text-white bg-gradient-to-tr from-blue-900 via-sky-900 to-blue-500  ">
            Artificial-Intelligence Projects
          </p>
          <Carousel project={aiprojects} />
        </div>
        <div className="p-20 space-y-8">
          <p className="text-3xl text-center p-3 rounded-xl font-bold text-white bg-gradient-to-tr from-blue-900 via-sky-900 to-blue-500  ">
            Education Technology Projects
          </p>
          <Carousel project={edtechprojects} />
        </div>
        <div className="p-20 space-y-8">
          <p className="text-3xl text-center p-3 rounded-xl font-bold text-white bg-gradient-to-tr from-blue-900 via-sky-900 to-blue-500  ">
            E-Commerce Projects
          </p>
          <Carousel project={ecommerceprojects} />
        </div>
      </div>
      <div className="h-fit w-full md:hidden">
        <Dropdown />
      </div>
    </div>
  );
};

export default OurWork;

// <div className=" hidden md:flex justify-center item-center w-full px-20 my-24">
//         <TabComponent />
//       </div>
//
