import { Link } from "react-router-dom";
import { PopUp } from "./PopUp";
import { RegularButton } from "../ui/Buttons";

export const CardComponent = (props) => {
  return (
    <div
      key={props.id}
      data-aos="fade-up"
      className={`xl:h-[300px] w-full xl:px-32`}
    >
      <div
        className={`w-full h-full flex flex-col ${
          props.id % 2 === 0 ? "xl:flex-row-reverse" : "xl:flex-row"
        } gap-8 rounded-xl hover:shadow-lg hover:shadow-sky-500 transition-all duration-300 overflow-hidden`}
      >
        <div className="xl:w-[50%] h-full p-2">
          <img
            className="h-full w-full object-cover rounded-xl"
            src={props.image}
            alt=""
          />
        </div>
        <div className=" xl:w-[40%] flex flex-col justify-center gap-2 xl:gap-4 p-4 xl:p-0">
          <p className="text-white text-2xl font-extrabold capitalize">
            {props.heading}
          </p>
          <p className="text-slate-500">{props.description}</p>
          <PopUp
            heading={props.heading}
            info={props.info}
            isPublic={props.isPublic}
            link={props.link}
          />
        </div>
      </div>
    </div>
  );
};

export const MemberCard = (props) => {
  return (
    <div className="h-400px  xl:w-[30%] flex py-8 shadow-md shadow-[#4082A2] flex-col justify-center items-center md:myshadow2 rounded-xl">
      <div className="flex flex-col justify-center items-center">
        <div className="xl:h-[200px] xl:w-[200px] h-[150px] w-[150px] outline outline-[#4082A2] outline-4 p-2  rounded-full overflow-hidden group flex justify-center items-center">
          <img
            className="object-cover h-full w-full  group-hover:scale-110 rounded-full transition-all duration-500"
            src={props.image}
            alt=""
          />
        </div>
        <div className="w-full h-[180px] mt-3 md:mt-5 xl:p-4">
          <p
            className={`w-full  font-bold text-3xl text-center text-[#4082A2]`}
          >
            {props.name}
          </p>
          <p className="w-full px-2 text-center font-semibold text-sm text-slate-500 mt-4">
            {props.about}
          </p>
        </div>
      </div>
    </div>
  );
};

export const SmallScreenMemberCard = (props) => {
  return (
    <div className={`flex text-white justify-center w-full gap-4`}>
      <div className="w-[40%] h-[150px] rounded-xl overflow-hidden outline outline-[#0987A0] p-1">
        <img
          className="w-full h-full object-cover rounded-xl"
          src={props.image}
          alt=""
        />
      </div>
      <div className=" w-[60%] h-full flex flex-col justify-between gap-4">
        <p className="text-2xl font-semibold text-[#4082A2]">{props.name}</p>
        <p className="text-xs">{props.about}</p>
      </div>
    </div>
  );
};

export const Card = (props) => {
  return (
    <Link
      to={props.link}
      data-aos={props.type}
      className=" w-[90%] md:w-[30%] h-[500px] flex flex-col justify-between items-center gap-3 md:pt-6 outline outline-2 py-5 outline-slate-500 p-2 rounded-xl"
    >
      <div className=" md:h-[60%] md:w-[98%] xl:h-[70%] xl:w-[98%] overflow-hidden rounded-xl group">
        <img
          className="object-cover h-full w-full group-hover:scale-110  transition-all duration-500"
          src={props.image}
          alt=""
        />
      </div>
      <div className="h-[40%] flex flex-col justify-betwee gap-5 mt-2">
        <p className="text-left w-full px-2 font-bold text-2xl">
          {props.title}
        </p>
        <p className="text-left w-full px-2 font-normal text-xl text-slate-500">
          {props.discription}
        </p>
        <RegularButton link={props.link} text="Explore More" />
      </div>
    </Link>
  );
};

export const ProjectDisplayCard = (props) => {
  return (
    <Link
      to={props.link}
      target="blank"
      className=" pointer-events-none hover:scale-95 outline outline-yellow-300"
    >
      <img src={props.img} alt={props.title} className="rounded-xl " />
    </Link>
  );
};

export const ProjectDisplayCardSM = (props) => {
  return (
    <Link
      to={props.link}
      target="blank"
      className="text-white pointer-events-none"
    >
      <img src={props.img} alt="projects" className=" w-full rounded-xl" />
      <div className=" py-2 text-center ">
        <p className="text-xl font-bold">{props.title}</p>
        <p className="tracking-wider">{props.description}</p>
      </div>
    </Link>
  );
};
