import React from "react";
import WorkWithUs from "../components/WorkWithUs";
import useScrollToTopOnMount from "../ui/useScrollToTopOnMount";


const Contact = () => {
  useScrollToTopOnMount();

  return (
    <div className="bg-[#0A0C11] flex flex-col items-center py-12 inter-regular">
      <WorkWithUs />
    </div>
  );
};

export default Contact;
