import React, { useState } from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import {
  aiprojects,
  ecommerceprojects,
  edtechprojects,
} from "../fragments/Data";
import { ProjectDisplayCardSM } from "../fragments/Cards";

const DropDown = () => {
  const [openDropDownAI, setOpenDropDownAI] = useState(true);
  const [openDropDownET, setOpenDropDownET] = useState(false);
  const [openDropDownEC, setOpenDropDownEC] = useState(false);

  const toggleDropDown = (dropdown) => {
    setOpenDropDownAI(dropdown === "AI" ? !openDropDownAI : false);
    setOpenDropDownET(dropdown === "ET" ? !openDropDownET : false);
    setOpenDropDownEC(dropdown === "EC" ? !openDropDownEC : false);
  };
  return (
    <div className=" flex flex-col justify-center items-center gap-4 px-5 pb-12">
      <div className="w-full space-y-3">
        <button
          onClick={() => toggleDropDown("AI")}
          className="text-white p-2 rounded-xl bg-gradient-to-tr from-[#2F669A] via-[#0c1c36] to-[#2F669A] w-full flex justify-between items-center gap-8 px-10"
        >
          <p className="font-semibold">Artificial Intelligence</p>
          {openDropDownAI ? <FaChevronDown /> : <FaChevronRight />}
        </button>
        <div
          className={`${
            openDropDownAI ? "flex" : "hidden"
          } flex-col gap-6 outline p-1 rounded-xl`}
        >
          {aiprojects.map((item) => (
            <ProjectDisplayCardSM {...item} />
          ))}
        </div>
      </div>
      <div className="w-full space-y-3">
        <button
          onClick={() => toggleDropDown("ET")}
          className="text-white p-2 rounded-xl bg-gradient-to-tr from-[#2F669A] via-[#0c1c36] to-[#2F669A] w-full flex justify-between items-center gap-8 px-10"
        >
          <p className="font-semibold">Education Technology</p>{" "}
          {openDropDownET ? <FaChevronDown /> : <FaChevronRight />}
        </button>
        <div
          className={`${
            openDropDownET ? "flex" : "hidden"
          } flex-col gap-6 outline p-1 rounded-xl`}
        >
          {edtechprojects.map((item) => (
            <ProjectDisplayCardSM {...item} />
          ))}
        </div>
      </div>
      <div className="w-full space-y-3">
        <button
          onClick={() => toggleDropDown("EC")}
          className="text-white p-2 rounded-xl bg-gradient-to-tr from-[#2F669A] via-[#0c1c36] to-[#2F669A] w-full flex justify-between items-center gap-8 px-10"
        >
          <p className="font-semibold">E-Commerce</p>{" "}
          {openDropDownEC ? <FaChevronDown /> : <FaChevronRight />}
        </button>
        <div
          className={`${
            openDropDownEC ? "flex" : "hidden"
          } flex-col gap-6 outline p-1 rounded-xl`}
        >
          {ecommerceprojects.map((item) => (
            <ProjectDisplayCardSM {...item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DropDown;
