import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { HeaderLinks } from "../fragments/Data";
import DrawerComponent from "../components/DrawerComponent";

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerStyle = {
    backgroundColor: scrollPosition > 0 ? "rgba(29, 63, 88, 0.5)" : "transparent",
    transition: 'background-color 0.5s ease',
    backdropFilter: scrollPosition > 0 ? 'blur(10px)' : 'none',
    boxShadow: scrollPosition > 0 ? '0px 2px 5px rgba(0, 0, 0, 0.3)' : 'none'
  };

  return (
    <div
      style={headerStyle}
      className="flex z-50 justify-between fixed items-center w-full px-6 py-3 md:px-16 md:py-2"
    >
      <Link to={"/"}>
        <div className="flex justify-center items-center gap-3">
          <img
            className="h-8 w-8 md:h-12 md:w-12 object-cover"
            src={logo}
            alt="Logo"
          />
          <div>
            <h1 className=" md:text-2xl inter-bold tracking-wide text-white">
              Sherwa.Tech
            </h1>
            <p className="text-xs inter-light hidden md:flex mt-2 text-slate-300 tracking-wide ">
              Guiding Your tech Ascent
            </p>
          </div>
        </div>
      </Link>
      <div className="hidden text-white md:flex gap-16 text-xl inter-medium ">
        {HeaderLinks.map((link) => (
          <Link key={link.id} to={link.url} className="tracking-wide hover:scale-95 duration-200">
            {link.title}
          </Link>
        ))}
      </div>
      <span className="block md:hidden relative cursor-pointer ">
        <DrawerComponent />
      </span>
    </div>
  );
};

export default Header;
