import React from "react";
import MovingComponent from "react-moving-text";
import { Link } from "react-router-dom";

const MyAnimatedTypo = () => {
  const Letters = ["S", "h", "e", "r", "w", "a", ".", "t", "e", "c", "h"];
  return (
    <span className=" absolute flex mt-8">
      {Letters.map((letter, index) => (
        <MovingComponent
          type="popIn"
          duration="1400ms"
          delay="index * 200ms"
          direction="alternate"
          timing="ease-in"
          iteration="infinite"
          fillMode="none"
          className="text-5xl text-[#fff] font-bold myfont"
        >
          {letter}
        </MovingComponent>
      ))}
    </span>
  );
};

const VideoComponent = () => {
  return (
    <div
      data-aos="fade-up"
      className="md:min-h-screen flex-col md:flex-row md:flex hidden bg-[#0A0C11] justify-center items-center py-12 md:py-0 md:px-24"
    >
      <div className="md:w-[50%] md:h-[400px] md:text-left md:px-8 md:pt-10 text-center flex flex-col justify-start items-center lg:items-start gap-4">
        <p className="text-3xl xl:text-4xl tracking-wide font-extrabold xl:font-bold text-white ">
          Inspired by the{" "}
          <span className="text-[#2F669A]">resilient Sherwa</span> tribe of the
          Himalayas!
        </p>
        <p className=" text-sm md:text-lg md:px-0 tracking-wide text-slate-400 px-6">
          We are dedicated to guiding businesses through their technological
          journey with expert solutions tailored to meet modern challenges.
        </p>
        <Link
          to="/work"
          className="text-white p-3 rounded-xl w-56 mt-4 font-semibold bg-[#254C72] text-center hover:scale-95 duration-200"
        >
          Explore Our Work
        </Link>
      </div>
      <div className="w-[50%] h-full object-cover hidden md:flex flex-col justify-center items-center p-8">
        <video
          className="h-full w-full object-cover shadow-md shadow-sky-600 relative rounded-xl"
          autoPlay
          loop
          muted
        >
          <source
            src="https://ik.imagekit.io/princeSherwa/Sherwa/video.mp4?updatedAt=1713807452227"
            type="video/mp4"
          />
        </video>
        <MyAnimatedTypo />
      </div>
    </div>
  );
};

export default VideoComponent;

// <img
//           className="md:hidden w-[80%]"
//           src="https://ik.imagekit.io/princeSherwa/Sherwa/solution.png"
//           alt=""
//         />
