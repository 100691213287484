import React from "react";

const Hero = () => {
  return (
    <div className="md:min-h-screen inter-regular lg:min-h-screen w-full bg-[#0A0C11] relative  flex flex-col items-center justify-center pt-64 pb-24 xl:pt-0">
      <img
        src="https://ik.imagekit.io/princeSherwa/Sherwa/blueglare.png"
        alt=""
        className="absolute object-cover xl:-top-28 top-32 blur-3xl"
      />
      <img
        className=" h-[40%] sm:h-[70%] xl:h-[60%] xl:top-36 myanimation top-44 absolute"
        src="https://ik.imagekit.io/princeSherwa/Sherwa/radiallines.png"
        alt=""
      />
      <div className="w-full z-40 flex flex-col justify-center items-center lg:pt-40 ">
        <p
          data-aos="fade-up"
          data-aos-delay="300"
          className=" text-5xl lg:text-8xl md:w-[60%] text-center font-extrabold text-white"
        >
          We{" "}
          <span className=" bg-gradient-to-tr from-purple-600 via-pink-600 to-blue-900   inline-block text-transparent bg-clip-text">
            build
          </span>{" "}
          digital experiences.
        </p>
        <p
          data-aos="fade-up"
          data-aos-delay="400"
          className=" w-56 md:w-full text-center md:text-2xl font-semibold mt-24 xl:mt-10 text-slate-300"
        >
          Scaling Your Tech Heights with Expert Guidance
        </p>
      </div>
    </div>
  );
};

export default Hero;
