import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import menu from "../assets/menu.gif";
import { HeaderLinks } from "../fragments/Data";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";

export default function DrawerComponent() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement] = useState("left");

  return (
    <>
      <Link colorScheme="blue" onClick={onOpen}>
        <img className="h-8 w-8 object-cover " src={menu} alt="" />
      </Link>
      <Drawer
        placement={placement}
        onClose={onClose}
        isOpen={isOpen}
        size={"xs"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton color={"red"} />
          <DrawerHeader
            className="text-center font-bold text-white flex justify-start items-center gap-6"
            borderBottomWidth="1px"
            bgColor={"#0A0C11"}
          >
            <img
              className="h-8 w-8 md:h-12 md:w-12 object-cover"
              src={logo}
              alt="Logo"
            />
            Sherwa.tech
          </DrawerHeader>
          <DrawerBody bgColor={"#0A0C11"}>
            <div className="flex flex-col gap-5 text-white mt-8">
              {HeaderLinks.map((link) => (
                <Link
                  onClick={onClose}
                  className="outline outline-1 p-3 md:text-lg rounded-lg font-bold text-center hover:scale-95 duration-200"
                  key={link.id}
                  to={link.url}
                >
                  {link.title}
                </Link>
              ))}
              <Link
                target="blank"
                className="bg-gradient-to-tr from-[#2F669A] via-[#0c1c36] to-[#2F669A] text-white text-center py-3 rounded-2xl font-bold text-xl hover:scale-95 duration-200"
                to="https://zcal.co/amansahu/60min"
              >
                Schedule a meeting
              </Link>
            </div>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
