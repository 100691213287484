import React from "react";
import { Card } from "../fragments/Cards";
import { workCard } from "../fragments/Data";

const Work = () => {
  return (
    <div className="bg-[#0A0C11] inter-regular text-white md:min-h-screen lg:min-h-screen w-full flex flex-col justify-center items-center gap-12 md:px-32">
      <div className="text-center space-y-5">
        <p className="text-center text-4xl font-semibold mt-4">Our Expertise</p>
        <p className="text-center text-slate-400 text-lg font-semibold">
          Boasting over eight years in full-stack development, our leaders have
          successfully powered startups and established businesses alike,
          incorporating cutting-edge technologies that drive growth and
          efficiency.
        </p>
      </div>
      <div className="flex flex-wrap w-full h-full justify-center md:justify-between items-center gap-10">
        {workCard.map((card) => (
          <Card
            link={card.link}
            image={card.image}
            title={card.title}
            discription={card.discription}
            type={card.type}
          />
        ))}
      </div>
    </div>
  );
};

export default Work;
