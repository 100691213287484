import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const RegularButton = (props) => {
  return (
    <Link
      to={props.link}
      className=" ml-2 w-fit px-4 py-1 rounded-md underline underline-offset-2 hover:no-underline  outline-slate-500 hover:bg-slate-400/[0.2] duration-200 "
    >
      {props.text}
    </Link>
  );
};

export const CtaButton = (props) => {
  return <Link></Link>;
};

export const FloatingButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 5000);

    return () => clearTimeout(timeout);
  }, []);

  return isVisible ? (
    <Link
      to={"https://zcal.co/amansahu/60min"}
      target="blank"
      className="fixed bottom-5 right-5 text-white w-20 space-y-2 hidden md:grid place-items-center z-50 hover:scale-95 duration-200 animate-pulse "
    >
      <img
        className="h-16 w-16 object-cover rounded-full"
        src="https://ik.imagekit.io/princeSherwa/Sherwa/schedule.png"
        alt="schedule"
      />
      <p className="text-center bg-gradient-to-tr from-[#2F669A] via-[#0c1c36] to-[#2F669A] text-xs uppercase font-semibold tracking-wider p-2 rounded-xl">
        Set up meeting!
      </p>
    </Link>
  ) : null;
};
